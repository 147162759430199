<template>
	<main>
		<MainHeader />
		<!--    <MainHeaderTemp />-->
		<NuxtPage />
		<MainFooter />
	</main>
</template>

<script lang="ts" setup>
import MainHeader from '~~/home/layouts/mainLayout/MainHeader.vue'
import MainFooter from '~~/home/layouts/mainLayout/MainFooter.vue'
import MainHeaderTemp from '~~/home/layouts/mainLayout/MainHeaderTemp.vue'

const { $router } = useNuxtApp()

const go = (key: string) => {
	console.log('---go', key)
	$router.push({ name: key })
}
</script>

<style lang="scss" scoped></style>
